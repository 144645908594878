import React from "react";
import landingImg from "../../../assets/images/hero-img.png";

const LandingSection = () => {
  return (
    <section id="hero" class="hero d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">
              We build software product experiences for startups & enterprises
            </h1>
            <h2 data-aos="fade-up" data-aos-delay="400">
              We help clients develop secure digital products that are centered
              around users needs and achieve business goals.
            </h2>
            <div data-aos="fade-up" data-aos-delay="600">
              <div class="text-center text-lg-start">
                <a
                  href="#about"
                  class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Get Started</span>
                  <i class="bi bi-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 hero-img"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src={landingImg} class="img-fluid" alt="landing_img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;

import React, { useEffect, useState } from "react";
import nameLogo from "../../assets/images/bnb_name_logo.png";

const NavBar = () => {
  const [iconState, setIconState] = useState("bi bi-list mobile-nav-toggle");
  const [navState, setNavState] = useState("navbar");
  const [activeIndex, setActiveIndex] = useState(0);

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  let navbarlinks = select("#navbar .scrollto", true);
  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      if (!navbarlink.hash) return;
      let section = select(navbarlink.hash);
      if (!section) return;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navbarlink.classList.add("active");
      } else {
        navbarlink.classList.remove("active");
      }
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 20) {
      document.querySelector(".header").className =
        "header fixed-top header-scrolled";
    } else {
      document.querySelector(".header").className = "header fixed-top";
    }
    navbarlinksActive();
  };

  const handleToggleNavBar = () => {
    if (iconState === "bi mobile-nav-toggle bi-x") {
      setIconState("bi mobile-nav-toggle bi-list");
      setNavState("navbar");
    } else {
      setIconState("bi mobile-nav-toggle bi-x");
      setNavState("navbar navbar-mobile");
    }
  };

  const handleClosePopUp = () => {
    setIconState("bi mobile-nav-toggle bi-list");
    setNavState("navbar");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const navValues = [
    { name: "Home", href: "#hero" },
    { name: "About Us", href: "#about" },
    { name: "Why Us?", href: "#values" },
    { name: "Services", href: "#services" },
    { name: "Contact", href: "#contact" },
  ];

  return (
    <header id="header" class="header fixed-top">
      <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/" class="logo d-flex align-items-center">
          <img src={nameLogo} alt="Bytes N Binary" />
        </a>

        <nav id="navbar" class={navState}>
          <ul>
            {navValues.map((val, index) => (
              <li key={val.href}>
                <a
                  class={
                    activeIndex === index
                      ? "nav-link scrollto active"
                      : "nav-link scrollto"
                  }
                  href={val.href}
                  onClick={() => {
                    setActiveIndex(index);
                    handleClosePopUp();
                  }}
                >
                  {val.name}
                </a>
              </li>
            ))}
            {/* <li>
              <a class="nav-link scrollto" href="#portfolio">
                Portfolio
              </a>
            </li> */}
            {/* <li>
              <a href="/">Blog</a>
            </li> */}
            {/* <li class="dropdown">
              <a href="#">
                <span>Drop Down</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href="#">Drop Down 1</a>
                </li>
                <li class="dropdown">
                  <a href="#">
                    <span>Deep Drop Down</span>{" "}
                    <i class="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="#">Deep Drop Down 1</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 2</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 3</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 4</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Drop Down 2</a>
                </li>
                <li>
                  <a href="#">Drop Down 3</a>
                </li>
                <li>
                  <a href="#">Drop Down 4</a>
                </li>
              </ul>
            </li> */}
            <li class="getstarted_li">
              <a
                class="getstarted scrollto"
                href="#about"
                onClick={() => {
                  handleClosePopUp();
                }}
              >
                Get Started
              </a>
            </li>
          </ul>
          <i
            class={iconState}
            onClick={() => {
              handleToggleNavBar();
            }}
          ></i>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;

import React from "react";
import nameLogo from "../../assets/images/bnb_name_logo.png";
import SocialIcons from "../socialIcons";

const Footer = () => {
  return (
    <footer id="footer" class="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12 footer-info">
              <a href="/" class="logo d-flex align-items-center">
                <img src={nameLogo} alt="Bytes N Binary" />
              </a>
              <p>
                Bytes N Binary is an India based organisation, delivering state
                of the art services of IT & IT Enabled Services to the global
                market. The company deals in Website design & Development,
                Software development, Mobile Applications development services.
              </p>
              <div class="social-links mt-3">
                <SocialIcons />
              </div>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i> <a href="/">Home</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="#about">About us</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Services</a>
                </li>
                {/* <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="/terms">Terms of service</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="/privacy-policy">Privacy policy</a>
                </li> */}
              </ul>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Web Applications</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Mobile Applications</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="#services">UI/UX Design</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Cloud Applications</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a href="#services">SaaS Products</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                Kollengode, Palakkad <br />
                Kerala, 678506,
                <br />
                India <br />
                <br />
                <strong>Phone:</strong> +91 8590869895
                <br />
                <strong>Email:</strong> info@bytesnbinary.com
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          &copy; 2022{" "}
          <strong>
            <span>Bytes N Binary</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/css/style.css";
import PageLayout from "./components/pageLayout";
import PageNotFound from "./components/pageNotFound";
import BnBHome from "./components/sections";
import UnderConstruction from "./components/underConstruction";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route element={<PageLayout />}>
            <Route path="/" element={<BnBHome />} />
          </Route>
          <Route path="/terms" element={<UnderConstruction />} />
          <Route path="/privacy-policy" element={<UnderConstruction />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

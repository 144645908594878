import React from "react";
import AboutSection from "./aboutSection";
import ContactSection from "./contactSection";
import LandingSection from "./landingSection";
import ServicesSection from "./servicesSection";
import ValuesSection from "./valuesSection";

const BnBHome = () => {
  return (
    <>
      <LandingSection />
      <main id="main">
        <AboutSection />
        <ValuesSection />
        {/* <CountsSection /> */}
        <ServicesSection />
        <ContactSection />
      </main>
    </>
  );
};

export default BnBHome;

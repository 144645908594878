import React from "react";
import "./style.css";

const SocialIcons = () => {
  return (
    <>
      <a href="/" target="_blank" rel="noopener noreferrer">
        <div class="bg-ico" id="facebook">
          <i class="fab bi bi-facebook social facebook" />
        </div>
      </a>
      <a
        href="https://www.instagram.com/bytesnbinary/?r=nametag"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="bg-ico" id="instagram">
          <i class="fab bi bi-instagram social instagram" />
        </div>
      </a>
      <a
        href="https://www.linkedin.com/company/bytesandbinary/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="bg-ico" id="linkedin">
          <i class="fab bi bi-linkedin social linkedin" />
        </div>
      </a>
      <a
        href="https://wa.me/+917306619179"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="bg-ico" id="whatsapp">
          <i class="fab bi bi-whatsapp social whatsapp" />
        </div>
      </a>
      {/* <a href="/">
        <div class="bg-ico" id="twitter">
          <i class="fab bi bi-twitter social twitter"/>
        </div>
      </a> */}
    </>
  );
};

export default SocialIcons;

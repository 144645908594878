import { Outlet } from "react-router-dom";
import Footer from "../footer";
import NavBar from "../navBar";

const PageLayout = () => (
  <>
    <NavBar />
    <Outlet />
    <Footer />
  </>
);

export default PageLayout;

import React from "react";

const ServicesSection = () => {
  return (
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Our Services</h2>
          <p>Experience the service you wished for</p>
        </header>

        <div class="row gy-4">
          <div
            class="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="service-box blue">
              <i class="bi bi-display icon"></i>
              <h3>Web Design & Development</h3>
              <p>
                We offer affordable pricing, industry-specific website and web
                app development with the latest web development technology
                integration and scalability. Our web developers combine their
                expertise with the latest technologies to resolve your business
                challenges.
              </p>
              {/* <a href="#" class="read-more">
                <span>Read More</span> <i class="bi bi-arrow-right"></i>
              </a> */}
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div class="service-box orange">
              <i class="bi bi-phone icon"></i>
              <h3>Mobile App Development</h3>
              <p>
                We design and develop mobile apps that impress your users and
                grow your business. We help startups, and enterprises to create
                app products and help them to launch in the market. We simplify,
                strengthen, and transform our client’s businesses.
              </p>
              {/* <a href="#" class="read-more">
                <span>Read More</span> <i class="bi bi-arrow-right"></i>
              </a> */}
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div class="service-box green">
              <i class="bi bi-person-workspace icon"></i>
              <h3>User Experience Design</h3>
              <p>
                Our UI/UX design and development services are focused on
                creating interfaces that make every digital interaction a
                delightful user experience. We love solving business problems by
                elevating user experience for our customers by thinking beyond
                designs.
              </p>
              {/* <a href="#" class="read-more">
                <span>Read More</span> <i class="bi bi-arrow-right"></i>
              </a> */}
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div class="service-box red">
              <i class="bi bi-cloud-check icon"></i>
              <h3>Cloud App Development</h3>
              <p>
                Cloud computing is the future. Using the cloud for IT processes
                makes daily computing also easier. It helps to build and launch
                business processes using the latest technologies . Switch to
                cloud based solutions now with the best cloud app developmnt
                company.
              </p>
              {/* <a href="#" class="read-more">
                <span>Read More</span> <i class="bi bi-arrow-right"></i>
              </a> */}
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div class="service-box purple">
              <i class="bi bi-globe icon"></i>
              <h3>Software as a Service Development</h3>
              <p>
                We offer custom software as a service development on all
                platforms using top technology stacks. Our team follows agile
                software development practices to deliver custom software
                products in the shortest possible time.
              </p>
              {/* <a href="#" class="read-more">
                <span>Read More</span> <i class="bi bi-arrow-right"></i>
              </a> */}
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            <div class="service-box pink">
              <i class="bi bi-graph-up-arrow icon"></i>
              <h3>Minimum Viable Product Development</h3>
              <p>
                We follow a lean and agile methodology for designing and
                releasing Minimum Viable Products (MVPs) for our clients. We
                have helped a large number of startups/enterprises from their
                launches to achieving product-market-fit.
              </p>
              {/* <a href="#" class="read-more">
                <span>Read More</span> <i class="bi bi-arrow-right"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./style.css";

const ContactSection = () => {
  const [btnClass, setBtnClass] = useState("snt-button");
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setBtnClass("snt-button snt-active");
    emailjs
      .sendForm(
        "service_3numyro",
        "template_mttzknc",
        form.current,
        "wKP_-ZFWvp6DDhzxg"
      )
      .then(
        (result) => {
          setBtnClass("snt-button snt-active snt-finished");
          setTimeout(() => setBtnClass("snt-button"), 4000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </header>

        <div class="row gy-4">
          <div class="col-lg-6">
            <div class="row gy-4">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>
                    Kollengode, Palakkad <br />
                    Kerala, India 678506
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>
                    +91 80780 54465
                    <br />
                    +91 73066 19179
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>
                    apps@bytesnbinary.com
                    <br />
                    bytesnbinary@gmail.com
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>
                    Monday - Friday
                    <br />
                    10:00AM - 07:00PM
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <form
              action="forms/contact.php"
              method="post"
              class="php-email-form"
              ref={form}
              onSubmit={sendEmail}
            >
              <div class="row gy-4">
                <div class="col-md-6">
                  <input
                    id="name"
                    type="text"
                    name="name"
                    class="form-control"
                    placeholder="Your Name"
                    required
                  />
                </div>

                <div class="col-md-6">
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="Your Email"
                    required
                  />
                </div>

                <div class="col-md-12">
                  <input
                    id="subject"
                    type="text"
                    class="form-control"
                    name="subject"
                    placeholder="Subject"
                    required
                  />
                </div>

                <div class="col-md-12">
                  <textarea
                    id="message"
                    class="form-control"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>

                <div class="col-md-12 text-center d-flex justify-content-center">
                  {/* <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">
                    Your message has been sent. Thank you!
                  </div>

                  <button type="submit">Send Message</button> */}
                  <button class={btnClass}>
                    <span class="snt-submit">Send Message</span>
                    {/* <span class="snt-loading">
                      <i class="bi bi-arrow-clockwise"></i>
                    </span> */}
                    <div
                      class="spinner-border text-light snt-loading"
                      role="status"
                    ></div>
                    <span class="snt-check">
                      <i class="bi bi-check-lg"></i>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import aboutUs from "../../../assets/images/about.png";

const AboutSection = () => {
  const [isViewMoreClicked, setViewMoreClicked] = useState(false);
  const [whyUs, setWhyUs] =
    useState(` We are a dynamic IT startup that specializes in developing
  cutting-edge web, mobile and SaaS-based applications. Our team
  is composed of experienced developers, designers and project
  managers who are passionate about creating innovative and
  user-friendly software.`);

  const viewmoreContent = `Our goal is to help businesses and organizations of all sizes streamline their operations and improve their bottom line by leveraging the latest technologies and industry best practices. We understand that every client has unique needs and requirements, which is why we work closely with each client to understand their goals and develop customized solutions that are tailored to their specific needs.
  We pride ourselves on delivering high-quality software on time and within budget. We use agile development methodologies to ensure that our clients are involved in the development process every step of the way and that we are able to respond quickly to changing requirements.
  In addition to our development services, we also offer ongoing support and maintenance to ensure that our clients' software stays up-to-date and free from bugs. We believe in building long-term relationships with our clients and are committed to providing exceptional service and support.`;

  const handleReadMore = () => {
    if (isViewMoreClicked) return;
    setViewMoreClicked(true);
    setWhyUs(whyUs + viewmoreContent);
  };
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row gx-0">
          <div
            className="col-lg-6 d-flex flex-column justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="content">
              <h3>Who We Are</h3>
              <h2>
                We are a team of tech-savvy innovators, dedicated to creating
                cutting-edge software solutions that drive business growth and
                success.
              </h2>
              <div className={isViewMoreClicked ? "height-increse" : ""}>
                <p>{whyUs}</p>
              </div>

              <div className="text-center text-lg-start">
                <a
                  className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center "
                  onClick={handleReadMore}
                >
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src={aboutUs} className="img-fluid" alt="About Us" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;

import React from "react";

const PageNotFound = () => {
  return (
    <section class="page_404">
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="col-sm-10 col-sm-offset-1  text-center d-flex justify-content-center flex-column">
              <div class="four_zero_four_bg">
                <h1 class="text-center">404</h1>
              </div>

              <div class="contant_box_404">
                <h3 class="h2">Look like you're lost</h3>

                <p>The page you are looking for is not available!</p>

                <a href="/" class="link_404">
                  Go to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;

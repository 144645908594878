import React from "react";
import agile from "../../../assets/images/agile-dev.jpg";
import communication from "../../../assets/images/communication.jpg";
import teams from "../../../assets/images/dedicated-teams.jpg";
import process from "../../../assets/images/process-oriented.jpg";
import quality from "../../../assets/images/quality-prod.jpg";
import support from "../../../assets/images/technology.png";

const ValuesSection = () => {
  const row1 = [
    {
      title: "Dedicated Teams",
      desc: "We understand that every business has unique needs and requirements when it comes to software development. That's why we offer dedicated teams to provide our clients with a customized and flexible approach to software development.",
      class: "col-lg-4",
      dataAosDelay: "200",
      image: teams,
    },
    {
      title: "Quality in every stride!",
      desc: "We believe that quality is the foundation of any successful software development project. That's why we strive for quality in every stride of our work, from the initial planning stages to the final deployment of the solution.",
      class: "col-lg-4 mt-4 mt-lg-0",
      dataAosDelay: "400",
      image: quality,
    },
    {
      title: "Effective communication!",
      desc: "We understand the importance of clear and timely communication throughout the entire project. Our team of experienced project managers, developers, and designers work closely with our clients to ensure that their needs and requirements are fully understood and met.",
      class: "col-lg-4 mt-4 mt-lg-0",
      dataAosDelay: "600",
      image: communication,
    },
  ];
  const row2 = [
    {
      title: "Impeccably process oriented!",
      desc: "We understand the importance of a well-defined and organized process in software development. That's why we have established an impeccable process-oriented approach that ensures that every project is completed efficiently and effectively.",
      class: "col-lg-4",
      dataAosDelay: "200",
      image: process,
    },
    {
      title: "Agile - watch your dreams grow!",
      desc: "We understand that software development is a constantly evolving process and that flexibility is key to delivering high-quality solutions that meet our clients' needs and expectations. That's why we use Agile methodologies to ensure that our projects are completed efficiently and effectively.",
      class: "col-lg-4 mt-4 mt-lg-0",
      dataAosDelay: "400",
      image: agile,
    },
    {
      title: ["Top Best", <br />, "Architectures!"],
      desc: "We understand that software development is constantly evolving and that new technologies and architectures are emerging to meet the needs of modern businesses. That's why we have embraced the Microservice and Microfrontend architecture to deliver high-quality software solutions that meet our clients' needs and expectations.",
      class: "col-lg-4 mt-4 mt-lg-0",
      dataAosDelay: "600",
      image: support,
    },
  ];
  return (
    <section id="values" class="values">
      <div class="container" data-aos="fade-up">
        <header class="section-header values-header">
          <h2>Why Us?</h2>
          <p>Why Clients Choose Us</p>
        </header>

        <div class="row values-rows">
          {row1.map((item) => (
            <div
              key={item.title}
              class={item.class}
              data-aos="fade-up"
              data-aos-delay={item.dataAosDelay}
            >
              <div class="box">
                <img src={item.image} class="img-fluid" alt="values-img" />
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <div class="row values-rows">
          {row2.map((item) => (
            <div
              key={item.title}
              class={item.class}
              data-aos="fade-up"
              data-aos-delay={item.dataAosDelay}
            >
              <div class="box">
                <img src={item.image} class="img-fluid" alt="values-img" />
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;

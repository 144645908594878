import React from "react";
import constructionImg from "../../assets/images/construction.svg";

const UnderConstruction = () => {
  return (
    <div className="h-100">
      <img
        src={constructionImg}
        alt="Under Development"
        className="w-100 h-100 under-dev"
      />
    </div>
  );
};

export default UnderConstruction;
